.heading{
  color:#0f3460;
}

.border-bg{
  background-color:#0f3460;
}

.cart-items {
  height: auto;
  padding: 50px 0;
}

.cart-items .cart-details {
  width: 70%;
}

.cart-items .cart-total {
  width: 30vw;
  margin-top: 30px;
  margin-left: 30px;
  height: 130px;
}
.cart-list {
  background: white;
  margin-top: 30px;
}
.card-img img{
  width: 75%;
  height: 75%;
}
.cart-items .cart-details h3 {
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
}
.cart-items .cart-details h4 {
  font-size: 15px;
  font-weight: 400;
  margin-top: 50px;
  color: grey;
}
.cart-items h4 span {
  color: #ebae05;
  margin-left: 20px;
  font-weight: 500;
}
.removeCart {
  background: none;
  font-size: 25px;
  text-align: right;
  margin-right: 10px;
}
.cartControl {
  margin-top: 50px;
}
.cartControl button {
  width: 40px;
  height: 40px;
  margin: 10px;
  border-radius: 5px;
  font-size: 20px;
}
.incCart {
  background: none;
  border: 1px solid rgb(3 0 71 / 9%);
  color: #ebae05;
}
.decCart {
  background: #f6f9fc;
}
.cart-total h4 {
  font-size: 15px;
  font-weight: 400;
}
.cart-total h3 {
  font-size: 20px;
  font-weight: 500;
  color: #ebae05;
}
.cart-total h2 {
  font-size: 18px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(3 0 71 / 9%);
  padding-bottom: 10px;
  color: #ebae05;
}
.no-items {
  color: #ebae05;
  font-size: 18px;
  margin-top: 30px;
  height: 130px;
}
.checkout{
  background-color: #0f3460;
  
}
.checkout:hover{
  background-color: rgb(88, 97, 129);
  
}

@media (max-width:680px){
  .cart-items .cart-total {
    width: 100%;
    margin: auto;
    height: auto;
  }
  .cart-items {
    height: auto;
    width: 100%;
  }
  .cart-items .cart-details {
    width: 100%;
    margin: auto;
  }
  
  .cart-list {
    background: white;
    margin-top: 30px;
  }
  .card-img img{
    width: 100%;
    height: 75%;
  }
  .cart-items .cart-details h3 {
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;
  }
  .cart-items .cart-details h4 {
    font-size: 15px;
    font-weight: 400;
    margin-top: 5px;
    color: grey;
  }
  .cart-items h4 span {
    color: #ebae05;
    margin-left: 15px;
    font-weight: 500;
  }
  .removeCart {
    background: none;
    font-size: 15px;
    text-align: right;
    margin-right: 10px;
  }
  .cartControl {
    margin-top: 5px;
  }
  .cartControl button {
    width: 20px;
    height: 20px;
    margin: 5px;
    border-radius: 5px;
    font-size: 12px;
  }
  .incCart {
    background: none;
    border: 1px solid rgb(3 0 71 / 9%);
    color: #ebae05;
  }
  .decCart {
    background: none;
    border: 1px solid rgb(3 0 71 / 9%);
    color: #b90404;
  }
  .cart-total h4 {
    font-size: 14px;
    font-weight: 400;
  }
  .cart-total h3 {
    font-size: 14px;
    font-weight: 500;
    color: #ebae05;
  }
  .cart-total h2 {
    font-size: 15px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(3 0 71 / 9%);
    padding-bottom: 10px;
    color: #ebae05;
  }
  .checkout{
    background-color: #0f3460;
    font-size: 12px;
    
  }
  .cart-items-function .d_flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .cart-total .d_flex{
    display: flex;
    flex-direction: row;

  }
}

@media (max-width:400px){
  .cart-items .cart-total {
    width: 100%;
    margin: auto;
    height: auto;
  }
  .cart-items {
    height: auto;
    width: 100%;
  }
  
  .cart-items .cart-details {
    width: 100%;
    margin: auto;
  }
  
  .cart-list {
    background: white;
    margin-top: 30px;
  }
  .card-img img{
    width: 100%;
    height: 75%;
  }
  .cart-items .cart-details h3 {
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;
  }
  .cart-items .cart-details h4 {
    font-size: 15px;
    font-weight: 400;
    margin-top: 5px;
    color: grey;
  }
  .cart-items h4 span {
    color: #ebae05;
    margin-left: 15px;
    font-weight: 500;
  }
  .removeCart {
    background: none;
    font-size: 15px;
    text-align: right;
    margin-right: 10px;
  }
  .cartControl {
    margin-top: 5px;
  }
  .cartControl button {
    width: 20px;
    height: 20px;
    margin: 5px;
    border-radius: 5px;
    font-size: 12px;
  }
  .incCart {
    background: none;
    border: 1px solid rgb(3 0 71 / 9%);
    color: #ebae05;
  }
  .decCart {
    background: none;
    border: 1px solid rgb(3 0 71 / 9%);
    color: #b90404;
  }
  .cart-total h4 {
    font-size: 14px;
    font-weight: 400;
  }
  .cart-total h3 {
    font-size: 14px;
    font-weight: 500;
    color: #ebae05;
  }
  .cart-total h2 {
    font-size: 15px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(3 0 71 / 9%);
    padding-bottom: 10px;
    color: #ebae05;
  }
  .checkout{
    background-color: #0f3460;
    font-size: 12px;
    
  }
  .cart-items-function .d_flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  .cart-total .d_flex{
    display: flex;
    flex-direction: row;

  }
}
