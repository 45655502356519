.scrollToTopBtn{
    position: fixed;
    bottom: 20px;
    left: auto;
    right: 20px;
    z-index: 2;
    width:100px;
}

.scrollToTopBtn button{
    background-color: #f08b08;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scrollToTopBtn button i{
    font-size: 1.5rem;
    font-weight: bold;
}
