.search-div{
    top:50px;
    left:0; 
    background-color: #fff; 
    color: #000; 
    z-index:2; 
    display: flex; 
    width: 100%; 
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
    
}
.search-table{
    height: 400px;
    overflow-y: scroll;
}
.fixed-header{
    width: 100%;
    height: 300px;
    overflow: auto;
    display:inline-block;
    justify-content: center; 
    align-items: center;
}
.fixed-header li{
    width: 100%;
    overflow: auto;
    display:flex;
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
}
.logout-hover:hover{
    color:blue;
}
