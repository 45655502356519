.head {
  background-color: #0f3460;
  padding: 10px 0;
  color: #fff;
}
.head i {
  margin-right: 10px;
}
.head label {
  margin-right: 30px;
  font-size: 13px;
}

.search {
  padding: 20px 0;
  background-color: white;
}
.search .active {
  height: 20vh;
}
.search-height {
  height: 50px;
}
.search .logo img {
  width: auto;
  height: auto;
}

.cart {
  position: relative;
}
.search .cart span {
  position: absolute;
  top: -10px;
  right: 0px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
  background-color: #ebae05;
  font-size: 12px;
  color: #fff;
}
.search .f_flex {
  justify-content: end;
}

.catgrories {
  background-color: #f6f9fc;
  padding: 0px 30px;
  border-radius: 5px;
  height: 6vh;
  margin-top: 5px;
}
.catgrories span {
  margin-right: 20px;
  font-size: 30px;
  margin-top: 10px;
}
.catgrories h4 {
  font-weight: 500;
  margin-top: 12px;
}
.catgrories h4 i {
  margin-left: 10px;
}
header {
  height: 8vh;
  box-shadow: -1px 15px 17px -8px rgba(0, 0, 0, 0.1);
}
header ul {
  line-height: 8vh;
}
header ul li {
  margin-left: 30px;
}
header ul li a {
  transition: 0.5s;
  font-size: 15px;
  font-weight: 400;
}
header ul li a:hover {
  color: #ebae05;
}

.open {
  display: none;
}
.search.active {
  height: 12vh;
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  background-color: #fff;
  box-shadow: -1px 11px 24px -11px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
.custom-border-color {
  border-bottom: 1px solid #0f3460;
}
.custom-text{
  color: #0f3460;
}
.custom-text:hover{
  color: #ebae05;
}
.custom-scroll{
  height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
@media (max-width: 768px) {
  header ul {
    padding-top: 30px;
  }
  .nav-links-mobile {
    position: absolute;
    display: block;
    list-style: none;
    box-shadow: rgba(50, 50, 93, 0.23) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    left: 0;
    top: 0;
    transition: all 0.5s ease-in-out;
    width: 60%;
    height: 100vh;
    background-color: white;
    z-index: 888;
  }
  header ul li {
    width: 100%;
    transition: all 0.5s ease-in-out;
    text-transform: uppercase;
    line-height: 70px;
  }
  header .link {
    display: none;
  }
  .close,
  .open {
    position: absolute;
    top: 35px;
    right: 20px;
    color: #ebae05;
    display: block;
    font-size: 25px;
    z-index: 999;
  }
  .close {
    right: 45%;
  }
  .close.home-btn {
    background: linear-gradient(145deg, #e2e8ec, #ffffff);
    color: #ebae05;
    border-radius: 50%;
    padding: 0;
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
  .header.active {
    height: 10vh;
  }
}


@media (max-width: 680px) {
  .head{
    margin: 0;
    padding: 0;
  }
  .topbar {
    display: inline-flex;
  }
}

@media (max-width: 400px) {
  .head{
    margin: 0;
    padding: 0;
  }
  .topbar {
    display: inline-flex;
  }
}
