.departmental {
  padding: 40px 0;
}
.slide-img {
  height: 250px;
  width: 100%;
}
.custom-wishlist{
  background-color: #ebae05;
  
}
.custom-wishlist i{
  font-size: 12px;
}
.viewall-btn{
  color:  #0f3460;
}


@media (max-width: 400px) {
 
  .card-title {
    font-size: medium;
  }
  .card-height{
    height: 150px;
  }
 
  .swiper {
    width: 50%;
    height: 50%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
}


#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

body {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

